import React, {
  memo,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
  useEffect,
} from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Userpic from "../../assets/images/userpic.jpg";
import { setUserProfile } from "../../reducers/userProfileSlice";
import { API_URL } from "../../services/Config";
import { authAPI } from "../../services/authAPI";
import { useNavigate } from "react-router-dom";
function UpdateUserProfile() {
  const dispatch = useDispatch();
  const access_token = useSelector(
    (state) => state.auth.access_token,
    shallowEqual
  );
  const refresh_token = useSelector(
    (state) => state.auth.refresh_token,
    shallowEqual
  );
  const userProfile = useSelector((state) => state.userProfile, shallowEqual);
  const fileInputRef = useRef(null);
  const defaultCountry = useRef([]);
  const defaultCurrency = useRef([]);
  const defaultSelected = useRef("");
  const [first_name, setFirstName] = useState(userProfile.first_name);
  const [last_name, setLastName] = useState(userProfile.last_name);
  const [email] = useState(
    userProfile.user !== "" ? userProfile.user : userProfile.user.email
  );
  const [company_name] = useState(userProfile?.company?.company_name);
  const [position, setPosition] = useState(userProfile.position);
  const [userLimit] = useState(userProfile.user_limit);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const checkImpUser = JSON.parse(localStorage.getItem("impersonateUserIds"));
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [errors, setErrors] = useState({});

  const fetchCountries = useCallback(async () => {
    try {
      const countriesData = await authAPI.countryList();
      setCountries(countriesData);
      const userCountry = countriesData.find(
        (ct) => ct.name === userProfile.country
      );
      setSelectedCountry(userCountry ? userCountry.id : "");
    } catch (error) {
      console.error("Error fetching country list:", error);
    }
  }, [userProfile.country]);

  const fetchCurrencies = useCallback(async () => {
    try {
      const currenciesData = await authAPI.getCurrencies({access_token});
      setCurrencies(currenciesData);
      const userCurrency = currenciesData.find(
        (ct) => ct.currencyCode === userProfile.currency
      );
      setSelectedCurrency(userCurrency ? userCurrency.currencyCode : "");
    } catch (error) {
      console.error("Error fetching country list:", error);
    }
  }, [userProfile.currency]);

  useEffect(() => {
    if (checkImpUser?.length > 1) {
      navigate("/");
    }
  }, [checkImpUser, navigate]);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  useEffect(() => {
    fetchCurrencies();
  }, [fetchCurrencies]);

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleCurrencyChange = (event) => {
    setSelectedCurrency(event.target.value);
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  // Function to handle image upload
  const handleImageUpload = useCallback(
    async (e) => {
      const selectedImage = e.target.files[0]; // Get the first selected image

      if (selectedImage) {
        try {
          const updatedProfileData = await authAPI.updateProfileImage({
            access_token,
            selectedImage,
          });
          
          if (updatedProfileData) {
            dispatch(
              setUserProfile({
                id: updatedProfileData?.id,
                user: updatedProfileData?.user.email,
                user_type: updatedProfileData?.user.user_type,
                eoris: updatedProfileData?.eoris,
                modules: updatedProfileData?.modules,
                first_name: updatedProfileData?.first_name,
                last_name: updatedProfileData?.last_name,
                profile_image: `${API_URL}${updatedProfileData?.profile_image}`,
                // company_registration: updatedProfileData?.company_registration,
                company: updatedProfileData?.company,
                user_limit: updatedProfileData?.user_limit,
                vat: updatedProfileData?.vat,
                position: updatedProfileData?.position,
                country: updatedProfileData?.user?.country,
                currency: updatedProfileData?.user?.currency,
              })
            );
            toast.success("Profile image updated successfully");
          }
        } catch (error) {
          toast.error("Error updating profile image");
          console.error("Error updating profile image:", error);
        }
      }
    },
    [access_token, dispatch]
  );

  const handleUpdateProfile = useCallback(async () => {
    try {
      if (access_token) {
        setIsLoading(true);
        setErrors({});
        const updatedProfileData = await authAPI.updateUserProfile({
          access_token,
          refresh_token,
          first_name,
          last_name,
          email,
          company_name,
          position,
          country: selectedCountry,
          currency: selectedCurrency,
        });
        if (updatedProfileData) {
          dispatch(
            setUserProfile({
              id: updatedProfileData?.id,
              user: updatedProfileData?.user,
              user_type: updatedProfileData?.user_type,
              is_internal_admin: updatedProfileData?.is_internal_admin,
              eoris: updatedProfileData?.eoris,
              modules: updatedProfileData?.modules,
              first_name: updatedProfileData?.first_name,
              last_name: updatedProfileData?.last_name,
              profile_image: `${API_URL}${updatedProfileData?.profile_image}`,
              // company_registration: updatedProfileData?.company.company_registration,
              company: updatedProfileData?.company,
              country: updatedProfileData?.country,
              currency: updatedProfileData?.currency,
              user_limit: updatedProfileData?.user_limit,
              vat: updatedProfileData?.vat,
              position: updatedProfileData?.position,
            })
          );
          const et = defaultCountry.current.find(
            (ct) => ct.name === updatedProfileData?.user?.country
          );
          // const et = defaultCurrency.current.find(
          //   (ct) => ct.name === updatedProfileData?.user?.currency
          // );
          defaultSelected.current = et?.id;
          toast.success("Profile updated successfully");
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      // toast.error("Getting something wrong..!");
      if (error.response?.data) {
        setErrors(error.response.data); // Capture validation errors
      } else {
        toast.error("An error occurred while updating the profile.");
      }
    }
  }, [
    access_token,
    company_name,
    dispatch,
    email,
    first_name,
    last_name,
    position,
    refresh_token,
    selectedCountry,
    selectedCurrency,
  ]);

  // const countryName = useCallback(() => {
  //   const ct = defaultCountry.current.find(
  //     (ct) => ct?.name === selectedCountry
  //   );
  //   return ct?.name;
  // }, [selectedCountry]);
  return (
    <div className="pagecontainer scrollablearea max-w-[800px] m-auto">
      <h1 className="text-3xl font-medium mb-8">My Profile</h1>
      <div className="bg-white xl:p-6 md:p-6 sm:p-4 rounded-lg  shadow shadow-gray-100">
        <div className="md:block sm:block lg:flex">
        <div className="w-1/5 lg:w-1/4 sm:w-full md:w-full">
            <div className="profile-outer md:m-auto md:mb-5 lg:m-0">
              <div className="profileImage">
                <img
                  src={
                    userProfile.profile_image !== null
                      ? userProfile.profile_image
                      : Userpic
                  }
                  onClick={handleImageClick}
                  alt="Profile"
                />
              </div>
              <input
                className="icon-upload-file"
                type="file"
                accept="image/*"
                ref={(ref) => (fileInputRef.current = ref)}
                style={{ display: "block" }}
                onChange={handleImageUpload}
              />
            </div>
          </div>
          <div className="w-4/5 lg:w-3/4 sm:w-full md:w-full">
            <div className="md:columns-2 sm:columns-1 xl:gap-5 sm:gap-6">
              <div className="w-full mb-3">
                <ToastContainer />
                <label className="mb-1 block">First Name:</label>
                <input
                  className={`bg-gray w-full rounded-lg border-primary h-12 border px-3 ${
                    errors.first_name ? "border-danger" : "border-primary"
                  }`}
                  type="text"
                  value={first_name}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                {errors.first_name && (
                  <p className="text-danger text-sm">{errors.first_name}</p>
                )}
              </div>
              <div className="w-full mb-3">
                <label className="mb-1 block">Last Name:</label>
                <input
                  className={`bg-gray w-full rounded-lg border h-12 px-3 ${
                    errors.last_name ? "border-danger" : "border-primary"
                  }`}
                  type="text"
                  value={last_name}
                  onChange={(e) => setLastName(e.target.value)}
                />
                 {errors.last_name && (
                  <p className="text-danger text-sm">{errors.last_name}</p>
                )}
              </div>
            </div>
            <div className="md:columns-2 sm:columns-1 xl:gap-5 sm:gap-6">
              <div className="w-full mb-3">
                <label className="block mb-1">Email:</label>
                <input
                  className="bg-gray w-full rounded-lg border-primary h-12 border px-3"
                  type="email"
                  value={email}
                  readOnly
                />
              </div>
              <div className="w-full mb-3">
                <label className="mb-1 block">Country:</label>
                <select
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  className={`bg-gray w-full rounded-lg h-12 border px-3 ${
                    errors.country ? "border-danger" : "border-primary"
                  }`}
                >
                  <option value="">Select Country</option>
                  {countries.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </select>
                {errors.country && (
                  <p className="text-danger text-sm">{errors.country}</p>
                )}
              </div>
            </div>
            <div className="clear-both"></div>
            {userProfile.user_type === "is_client_admin" && (
            <div className="md:columns-2 sm:columns-1 xl:gap-5 sm:gap-6">
              <div className="w-full mb-3">
                <label className="mb-1 block">Company:</label>
                <input
                  type="text"
                  className="bg-gray w-full rounded-lg border-primary h-12 border px-3"
                  value={company_name}
                  readOnly
                />
              </div>
              <div className="w-full mb-3">
                <label className="mb-1 block">Position:</label>
                <input
                  type="text"
                  className="bg-gray w-full rounded-lg border-primary h-12 border px-3"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                />
              </div>

            </div>
            )}
            <div className="md:columns-2 sm:columns-1 xl:gap-5 sm:gap-6">
              <div className="w-full mb-3">
                {userProfile.user_type === "is_client_admin" && (
                  <>
                    <label className="block mb-1">User Limit:</label>
                    <input
                      className="bg-gray w-full rounded-lg border-primary h-12 border px-3"
                      type="text"
                      value={userLimit}
                      readOnly
                    />
                  </>
                )}
              </div>
              <div className="w-full mb-3" style={{display: 'inline-block'}}>
                <label className="mb-1 block">Your Default Currency:</label>
                <select
                  value={selectedCurrency}
                  onChange={handleCurrencyChange}
                  className={`bg-gray w-full rounded-lg h-12 border px-3 ${
                    errors.currency ? "border-danger" : "border-primary"
                  }`}
                >
                  <option value="">Select Currency</option>
                  {currencies.map((currency) => (
                    <option key={currency.id} value={currency.currencyCode}>
                      {currency.name}
                    </option>
                  ))}
                </select>
                {errors.currency && (
                  <p className="text-danger text-sm">{errors.currency}</p>
                )}
              </div>
            </div>
            <div className="clear-both"></div>
            <div className="text-right">
              <button
                className="button-default my-3"
                onClick={handleUpdateProfile}
                disabled={isLoading}
              >
                {isLoading ? "Please wait..." : "Update Profile"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(UpdateUserProfile);
