import { Amplify, Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Iconeyeoff from '../../assets/images/eye-off-svgrepo-com.svg';
import Iconeye from '../../assets/images/eye-svgrepo-com.svg';
import '../../assets/styles/custom.css';
import { loginSuccess, setUserToken } from '../../reducers/authSlice';
import { unsetSuccessMessage } from '../../reducers/messageSlice';
import { setUserProfile } from '../../reducers/userProfileSlice';
import { authAPI } from '../../services/authAPI';
import awsconfig from './../../services/aws-exports';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';


Amplify.configure(awsconfig.auth);

function Login() {
  const message = useSelector(state => state.message.successMessage);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [openTermsPopup, setOpenTermsPopup] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  let navigate = useNavigate();

  // To close the terms popup
  const handleTermsPopupClose = () => {
    setOpenTermsPopup(false);
  };

  // Define validation schema using Yup
  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required('Please enter a Username'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('Please enter a Password'),
    // tandc: Yup.boolean()
    //   .oneOf([true], 'You must accept the terms and conditions')
    //   .required('You must accept the terms and conditions')
  });

  // Initialize Formik
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',      
      // tandc: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const response = await authAPI.login(values);

        if (response.status === 400) {
          throw new Error(response.data?.errors || 'Invalid credentials');
        }
        
        const username = values.email;
        const password = values.password;
        // const tandc = values.tandc;
        // const response = await Auth.login({ username, password });
        
        if (response) {
          // const storedData = { ...response.storage };
          // for (const key in storedData) {
          //   try {
          //     storedData[key] = JSON.parse(storedData[key]);
          //   } catch (error) {
          //     // Ignore JSON parsing errors for non-JSON values
          //   }
          // }
          const response_values =JSON.stringify({ access : response?.token[0]?.access, refresh : response?.token[0]?.refresh, payload_data:response.storage });
          // const { token } = response_values;
          // const userData = JSON.parse(response_values);
          // localStorage.setItem("impersonateFirstUserToken", userData?.access);
          // localStorage.setItem("impersonateFirstUserRefreshToken", userData?.refresh);
          dispatch(setUserToken(JSON.parse(response_values)));
          dispatch(loginSuccess());
          dispatch(unsetSuccessMessage({ message: null }));
          fetchUserProfile(response_values.access);
          navigate('/');
        }
      } catch (error) {
        setError('Invalid email or password.');
        console.log('Error during login:', error);
      } finally {
        setSubmitting(false); // Set submitting to false after response (success or error)
      }
    },
  });

  // Fetch user profile using access token
  const fetchUserProfile = async (access_token) => {
    try {
      if (access_token) {
        const profileData = await authAPI.getUserProfile({ access_token });
        if (profileData) {
          dispatch(setUserProfile(profileData));
        }
      }
    } catch (error) {
      console.log('Error fetching user profile:', error);
    }
  };

  return (
    <div>
      <div className='logincontainer-outer'>
        <div className='logincontainer'>
        <div className="flex justify-center">
            <div className="animated-logo">
              <div className="animated-logo-360"></div>
            </div>
          </div>
          {error && <p className="text-danger font-weight-bold mb-2">{error}</p>}
          <form onSubmit={formik.handleSubmit}>
          {message && <p className="success-message">{message}</p>}
          
            <div className="form-group mb-5">
              <input
                type='text'
                placeholder='Email'
                name='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className='error-message'>{formik.errors.email}</div>
              ) : null}
            </div>
            <div className="form-group mb-5 relative">
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder='Password'
                name='password'
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <span
                className="eye-icon"
                onClick={() => setShowPassword(!showPassword)} // Toggle the state
              >
                {showPassword ? <img src={Iconeye} width="18" alt='eye' /> : <img src={Iconeyeoff} width="18" alt='show password' />} {/* Toggle the eye icon */}
              </span>

              {formik.touched.password && formik.errors.password ? (
                <div className='error-message'>{formik.errors.password}</div>
              ) : null}
            </div>
            {/* <div className="form-group mb-5 relative">
              <label>
              <input
                type='checkbox'
                name='tandc'
                value={formik.values.tandc}
                onChange={formik.handleChange}
                style={{height: "20px", width: "20px"}}
                // onBlur={formik.handleBlur}
              /> I accept the <strong onClick={() => setOpenTermsPopup(true)}>Terms and Conditions</strong>
             </label>
              {formik.touched.tandc && formik.errors.tandc ? (
                <div className='error-message'>{formik.errors.tandc}</div>
              ) : null}
            </div> */}
            
            <div className='flex items-center justify-between mt-5'>
              <div className='forgot-link'>
                <NavLink to="/forgot-password">
                  <span>Forgot Password?</span>
                </NavLink>
              </div>
              <div className='text-right'>
                <button type='submit' disabled={formik.isSubmitting}>
                  {formik.isSubmitting ? 'Logging in...' : 'Login'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
        {/* Terms popup */}
        <Dialog fullScreen={fullScreen} open={openTermsPopup} onClose={handleTermsPopupClose}>
        <DialogTitle><Typography align="center">Terms and Conditions</Typography></DialogTitle>
        <DialogContent>
          <p>This is a simple popup in React using MUI. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTermsPopupClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Login;
