import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Button,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCurrencyList } from "../../../reducers/userProfileSlice";
import ClearIcon from "@mui/icons-material/Clear";
import { currencyIcon } from "../../analytical/AnalyticalCommon";
import { API_URL } from "../../../services/Config";
import Swal from 'sweetalert2';


const CurrencyFilter = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.access_token);
  const userProfile = useSelector((state) => state.userProfile);
  const hasFetched = useRef(false);
  const [loading, setLoading] = useState(false);
  const currencyFlagMap = currencyIcon();

  // State to store fetched currency data
  const [currencyData, setCurrencyData] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(() => {
    return window.localStorage.getItem("viewCurrency") || userProfile?.currency || "GBP";
  });

  const fetchCurrenciesList = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}/api/analytics/widgets/currencies/`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      );
      const data = await response.json();
      dispatch(setCurrencyList(data));
      setCurrencyData(data);
      // setIsFetched(true);
    } catch (error) {
      console.error("Error fetching currencies list:", error);
    }
    setLoading(false);
  }, [dispatch, access_token]);

  useEffect(() => {
    if (access_token && !hasFetched.current) {
      fetchCurrenciesList();
      hasFetched.current = true;
    }
  }, [access_token, fetchCurrenciesList]);

  const handleCurrencyChange = async(event) => {
    const { value: selectedValue } = event.target;
    setSelectedCurrency(selectedValue);
    if(selectedValue  == 'GBP'){
      const result = await Swal.fire({
            title: 'Error Occurred',
            text: `An error occurred converting to your chosen Currency. Please try again and report to the C360 Support team if the issue persists.`,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'Okay',
            // cancelButtonText: 'Okay',
          });
      
          if (result.isConfirmed) {
            handleClose();
            return false;
            }
    }
    window.localStorage.setItem("viewCurrency", selectedValue);

    // Assuming currencyData is an array of objects where each object has a 'currencyCode' and 'symbol' property
    const selectedCurrencyData = currencyData.find(
      (currency) => currency.currencyCode === selectedValue
    );
    if (selectedCurrencyData) {
      window.localStorage.setItem(
        "currencySymbol",
        selectedCurrencyData.symbol
      );
      window.dispatchEvent(new Event("symbolUpdate"));
    }

    window.dispatchEvent(new Event("currencyUpdate"));
    handleClose(); // Close the popup after currency selection
  };

  const handleClearCurrency = () => {
    setSelectedCurrency("");
    window.localStorage.removeItem("viewCurrency");
    window.localStorage.removeItem("currencySymbol");
    window.dispatchEvent(new Event("currencyUpdate"));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={false}
      className="currencyModal"
    >
      <DialogTitle
        sx={{ display: "flex", position: "relative", padding: "15px" }}
        color={"#000"}
      >
        Select Currency
      </DialogTitle>
      <IconButton
        onClick={handleClose}
        className="closeButton"
        sx={{ marginLeft: "8px" }}
      >
        <ClearIcon />
      </IconButton>
      <DialogContent sx={{ padding: "0" }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70%",
            }}
          >
            <CircularProgress sx={{ color: "#ff6b04" }} />
          </Box>
        ) : (
          <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControl
                variant="filled"
                sx={{ m: 1, minWidth: 180 }}
                className="columnselect"
              >
                <Select
                  displayEmpty
                  value={selectedCurrency}
                  onChange={handleCurrencyChange}
                  sx={{ fontSize: "13px" }}
                >
                  <MenuItem value="" disabled>
                    Currency
                  </MenuItem>
                  {currencyData?.map((currency, index) => (
                    <MenuItem
                      className="columnlist"
                      key={index}
                      value={currency?.currencyCode}
                    >
                      <span className="flag-icon">
                        {currencyFlagMap[currency?.currencyCode]}
                      </span>
                      {currency?.currencyCode} {currency?.symbol}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {selectedCurrency && (
              <Box sx={{ display: "flex", alignItems: "center", justifyContent:'center' }}>
                <Button className="btn-orange"
                  variant="contained"
                  onClick={handleClearCurrency}
                  sx={{ margin: "15px" }}
                >
                  Remove Currency Filter
                </Button>
              </Box>
            )}
          </>
        )}
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions> */}
    </Dialog>
  );
};

export default CurrencyFilter;
