import {
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Collapse,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { authAPI } from "../../../services/authAPI";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { useGlobalContext } from '../../../hook/globalstate';

const ExpandableTableDataChart = ({
  widgetName,
  widgetTitle,
  widgetData,
  leftItem = null,
  helpText,
  viewHelpText,
  dropKey,
  settingId,
  isPrint = false
}) => {
  const location = useLocation();
  const pathType = location.pathname;
  const name = widgetName;
  const title = widgetTitle;
  const [loading, setLoading] = useState(false);
  const [tableChartData, setTableChartData] = useState(
    widgetData || null || []
  ); // Change to null to handle loading state properly
  const [isFetched, setIsFetched] = useState(false); // State to track if fetching is complete
  const { state, setState } = useGlobalContext()
  const access_token = useSelector(
    (state) => state.auth.access_token,
    shallowEqual
  );
  const userProfile = useSelector((state) => state.userProfile);
  const hasFetched = useRef(false); // Use a ref to track if the API has been called
  const [expanded, setExpanded] = useState(false)
  const [userCurrency, setUserCurrency] = useState();
  const currencySymbol = window.localStorage.getItem("currencySymbol");
  
    const fetchCurrencies = useCallback(async () => {
      try {
        const currenciesData = await authAPI.getCurrencies({access_token});
       
        const userCurrency = currenciesData.find(
          (ct) => ct.currencyCode === userProfile.currency
        );
        setUserCurrency(userCurrency ? userCurrency : "");
      } catch (error) {
        console.error("Error fetching country list:", error);
      }
    }, [userProfile.currency]);
  
    useEffect(() => {
      fetchCurrencies();
    }, [fetchCurrencies]);

  useEffect(() => {
    window.addEventListener("filterUpdate", fetchTableData);
    window.addEventListener("currencyUpdate", fetchTableData);

    return () => {
      window.removeEventListener("filterUpdate", fetchTableData);
      window.removeEventListener("currencyUpdate", fetchTableData);
    };
  }, []);

  const fetchTableData = async () => {
    setLoading(true);
    try {
      const filters = window.localStorage.getItem("filters");
      const viewCurrency = window.localStorage.getItem("viewCurrency");
      const formData = new FormData();
      formData.append("name", name);
      if (filters !== "" && filters !== null && filters !== undefined)
        formData.append("filters", filters || []);
      formData.append("viewCurrency", (viewCurrency || userProfile?.currency || "GBP"));
      formData.append("userCurrency", (userProfile?.currency || "GBP"));
      const response = await authAPI.fetchInfoDisplayWidget({
        access_token,
        formData,
      });
      // toast.success(response?.message);
      setTableChartData(response?.result || []);
      setIsFetched(true); // Set the fetching complete state to true
    } catch (error) {
      toast.error(error?.message);
    }
    setLoading(false);
  };

      useEffect(() => {
        if (window.localStorage.getItem("viewCurrency") || userProfile?.currency) {
          fetchTableData();
        }
      }, [window.localStorage.getItem("viewCurrency"), userProfile?.currency]);

  useEffect(() => {
    if (access_token && !hasFetched.current && !widgetData) {
      fetchTableData();
      hasFetched.current = true; // Set the flag to true after the first call
    }
  }, [access_token, widgetData]);

  // Function to format the number
  function formatNumber(value) {
    return value?.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 3,
    });
  }

  useEffect(() => {
    if (leftItem !== null && leftItem?.[0]?.item === widgetName) {
      fetchTableData();
    }
  }, [leftItem]);

  let widgetSettings = state.settingData[`${settingId}-${dropKey}`];

  if (loading) {
    return (
      <div>
        <Box
          className={`item-content ${isPrint ? "print" : ""}`}
          sx={{ paddingTop: "160px", height: "400px" }}
        >
          <Box className="dashboard-item">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress sx={{ color: "#ff6b04" }} />
            </Box>
          </Box>
        </Box>
      </div>
    );
  }
  
  const tableDefaultData = tableChartData.slice(0, 20); 
  const tableExpandedData = tableChartData.slice(20); 
  
  return (
    <div>
      <Box
        className={`item-content tooltip-outer viewChart ${isPrint ? "print" : ""}`}
        sx={{ height: "400px", paddingBottom: "60px" }}
      >
        {pathType === "/analyticals" &&
          viewHelpText !== "" &&
          viewHelpText !== undefined &&
          viewHelpText !== null ? null : pathType === "/analyticals" ? (
            <Tooltip title={helpText} placement="right" className="info-tooltip">
              <IconButton sx={{ p: "0" }}>
                <InfoIcon sx={{ color: "#000" }} />
              </IconButton>
            </Tooltip>
          ) : null}
        <Box className="dashboard-item">
          {(isFetched && tableChartData?.length === 0 ) || tableChartData?.length === 0 ? (
            <Box className="" sx={{ height: '400px' }}>
            <Typography sx={{ textAlign: 'left', fontWeight: '600', padding: '5px 10px', fontSize: '20px' }}>
                {widgetTitle}
            </Typography>
            <Box className="dashboard-item">
                <Typography variant="h6" sx={{ textAlign: 'center', p: 20 }}>
                    No result found
                </Typography>
            </Box>
        </Box>
          ) : (
            <div className="tableWidgetParent">
              {tableChartData?.length ? <Box className={`series-table-container ${tableChartData?.length >= 20 ? 'series-expendable-table-container' : ''}`}>
                <Typography
                  sx={{
                    textAlign: "left",
                    fontWeight: "600",
                    padding: "5px 10px",
                    fontSize: "20px",
                  }}
                >
                  {widgetSettings?.hideLabel ? '' : widgetSettings?.label ? widgetSettings.label : widgetTitle}
                </Typography>

                <TableContainer component={Paper} className="fdsfdsfdsdf">
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="commodity codes table"
                  >
                    <TableHead>
                      <TableRow className="mat-header-row">
                        <>
                          <TableCell className="mat-header-cell">
                            Deferment Number
                          </TableCell>
                          <TableCell className="mat-header-cell">
                            Total Duty Paid
                          </TableCell>
                          <TableCell className="mat-header-cell">Total VAT Paid</TableCell>               
                        </>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableDefaultData?.map((row, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              {row?.Deferment_Number}
                            </TableCell>
                            <TableCell>
                              {row?.Total_Duty_Paid ? (currencySymbol || userCurrency?.symbol || "£") + formatNumber(row?.Total_Duty_Paid) : ''}    
                            </TableCell>
                            <TableCell>
                            {row?.Total_VAT_Paid ? (currencySymbol || userCurrency?.symbol || "£") + formatNumber(row?.Total_VAT_Paid) : ''}     </TableCell>                            
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>                  
                  <Collapse in={expanded} timeout='auto' unmountOnExit>
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="commodity codes table"
                  >                    
                    <TableBody>
                      {tableExpandedData?.map((row, index) => {
                        return (
                          <TableRow key={index} className="mat-header-row">
                            <TableCell sx={{minWidth: '100px'}}>
                              {row?.Deferment_Number}
                            </TableCell>
                            <TableCell>
                              {row?.Total_Duty_Paid}
                            </TableCell>
                            <TableCell>{row?.Total_VAT_Paid} </TableCell>                            
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Collapse>
                </TableContainer>
                
              </Box> : null}

              {pathType === "/analyticals" && viewHelpText ? (
                <p className="printInfoText">{viewHelpText}</p>
              ) : null}
            </div>
          )}
        </Box>
        {
          tableChartData?.length >= 20 &&        
            <div className="expandable-btn">
              <Tooltip
                className="detail-tooltips upload-btn"
                title="Click here to view all data"
              >
                <IconButton
                  onClick={() => setExpanded(!expanded)}
                > 
                {expanded ? <VisibilityOffIcon sx={{ color: "#7f7f7f" }} /> : <VisibilityIcon sx={{ color: "#7f7f7f" }} /> }                  
                </IconButton>                                
              </Tooltip>
            </div>     
        }
      </Box>
    </div>
  );
};

export default ExpandableTableDataChart;
