import { Box, Card, Modal, Tab, Tabs, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomCalendar from './Filters/customCalendar';
import Filter from './Filters/filters';
import CurrencyFilter from './Filters/currency';
import InfoCard from './InfoCard/index';
import ActionsCard from './ActionCard/index';
import Swal from 'sweetalert2';
import { authAPI } from '../../services/authAPI';
import { shallowEqual, useSelector } from 'react-redux';
import IconCurrencies from '../../assets/images/icon-currency.svg';
import IconDeclarant from '../../assets/images/icon-declarants.svg';
import IconCountry from '../../assets/images/icon-country.svg';
import { useGlobalContext } from '../../hook/globalstate';

const KpiBoxes = (props) => {
  const { handlePrint, itemTitle, handleSaveClick, handleUpdateClick, widgetId, fetchWidgetReport, uniqueCurrencies, declarantNumber,getFilterValueData, filterRemoveFunction, filteredData,totalImportValue } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [currencyFilter, setCurrencyFilter] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [tabValue, setTabValue] = useState('your');
  const navigate = useNavigate();
  const { states, setState } = useGlobalContext()
  const access_token = useSelector((state) => state.auth.access_token, shallowEqual);

  const cardData = [
    { icon: IconCurrencies, number: uniqueCurrencies, value: '', description: 'Total VAT Paid (IMPORTS)' },
    { icon: IconDeclarant, number: declarantNumber, value: '', description: 'Total Customs Duty Paid (IMPORTS)' },
    { icon: IconCountry, number: totalImportValue, value: '', description: 'Total Customs Value (IMPORTS)' },
  ];

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleCurrencyFilter = () => {
    setCurrencyFilter(!currencyFilter);
  };

  const handleSaveWidgets = () => {
    setShowPopup(true); // Show the popup when report is saved
  };
  const handleWidgetsPopupSave = () => {
    handleSaveClick(true);
  };

  const handleUpdateWidget = () => {
    // alert("handleUpdateWidget")
    handleUpdateClick(true);
  };

  const handleEditWidget = () => {
    // alert("handleEditWidget");
    var selectedReportId = localStorage.getItem('selectedReportId');
    var selectedReportTitle = localStorage.getItem('selectedReportTitle');

    if (!selectedReportId) {
      selectedReportId = widgetId;
      selectedReportTitle  = itemTitle;
    }

    // navigate(`/edit-report/${widgetId}`, {
    //   state: {
    //     title: itemTitle, id: widgetId
    //   }
    // });
    navigate(`/edit-report/${selectedReportId}`, {
      state: {
        title: selectedReportTitle, id: selectedReportId
      }
    });
  };
  const handleDeleteWidget = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: true,
      allowOutsideClick: false,
      showCloseButton: true,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: `Do you really want to delete this report!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const formData = new FormData();
            formData.append('id', widgetId);
            const response = await authAPI.deleteWidgetById({ access_token, widgetId });

            if (response.status) {
              swalWithBootstrapButtons.fire({
                title: "Deleted!",
                text: `Your report has been deleted.`,
                icon: "success",
              });

              fetchWidgetReport();
              setState({ type: "clearSettingData" });
            }
          } catch (error) {
            console.log(error);
          }
        } else if (result?.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: `Your widget is safe!`,
            icon: "error",
          });
        }
      });
  };

  const handleActionClick = (action) => {
    const actionHandlers = {
      'Add': () => navigate('/create-report'),
      'Edit': handleEditWidget,
      'Delete': handleDeleteWidget,
      'Filter': handleFilter,
      'Event': handleCalendar,
      'Print': handlePrint,
      'Currency Exchange': handleCurrencyFilter,
      'Save': handleSaveWidgets,
      'UpdateWidget': handleUpdateWidget,
    };

    const handler = actionHandlers[action];
    if (handler) {
      handler();
    } else {
      console.error(`No handler found for action: ${action}`);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    localStorage.removeItem('report_value')
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    window.localStorage.setItem('report_value', newValue)
  };

  const filterDataView = (filterView) => {
    getFilterValueData(filterView);
  };

  const removeSelectedFilter = (removeSelected) => {
    filterRemoveFunction(removeSelected)
  }

  return (
    <>
      <Card sx={{ display: 'flex', boxShadow: 'none', background: 'none', position: 'relative', zIndex: '10', overflow: 'visible', alignItems: 'center', width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            paddingBottom: '5px',
            width: '100%'
          }}
        >
          <ActionsCard handleActionClick={handleActionClick} widgetId={widgetId} />
          {cardData.map((card, index) => (
            <InfoCard
              key={index}
              icon={card.icon}
              number={card.number}
              value={card.value}
              description={card.description}
            />
          ))}
        </Box>
      </Card>
      {showCalendar && (<CustomCalendar open={showCalendar} handleClose={handleCalendar} />)}
      {showFilter && (<Filter open={showFilter} handleClose={handleFilter} filterDataView={filterDataView} removeSelectedFilter={removeSelectedFilter} filteredData={filteredData} />)}
      {currencyFilter && (<CurrencyFilter open={currencyFilter} handleClose={handleCurrencyFilter} />)}

      <Modal open={showPopup} onClose={handleClosePopup}>
        <Box sx={{ width: '400px', margin: 'auto', marginTop: '10%', backgroundColor: 'white', padding: '20px', borderRadius: '8px', boxShadow: 24 }}>
          <Typography variant="h6" gutterBottom>
            Select the Location to Save Report
          </Typography>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '15px' }}>
            <Tabs value={tabValue} onChange={handleTabChange} centered >
              <Tab value="your" label="your report" />
              <Tab value="shared" label="Shared report" />
            </Tabs>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <Button variant="outlined" sx={{background:'#f36b1c', color:'#fff', borderColor:'#f36b1c',marginRight:'5px'}} className='btn-cancel' onClick={handleClosePopup}>Cancel</Button>
            <Button variant="contained" sx={{background:'#f36b1c'}}  color="primary" className='button-default' onClick={handleWidgetsPopupSave}>Create</Button>          
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default KpiBoxes;
